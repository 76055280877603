import {Component, Input} from '@angular/core';

@Component({
    selector: 'mir-ecawin-participant',
    templateUrl: './ecawin-participant.component.html',
    styleUrls: ['./ecawin-participant.component.css'],
    standalone: false
})
export class EcawinParticipantComponent {

  @Input()
  participant: any = null;

}
