import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EcawinParticipantComponent} from './ecawin-participant/ecawin-participant.component';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";


@NgModule({
  declarations: [
    EcawinParticipantComponent
  ],
  exports: [
    EcawinParticipantComponent,
    BsDatepickerModule,
    CommonModule
  ],
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
  ]
})
export class SharedModule { }
